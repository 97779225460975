.item-row a{
  color: #212529;
    text-decoration: none;
}
.st-o-name{
	text-transform: capitalize;
}
.store-info{
	
}
.store-info .inner-content{
	
}
.store-info .inner-content .store-info-row{
	
}
.store-photo{
    width:100%;
}
.store-details p{
	font-size: 16px;
    font-weight: bold;
    color: #6d6a6a;

}
.item-row .dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.item-row .dropdown span{
  font-weight: bold;
}
.item-row .dropdown ul li a{
  width: 100%;
    display: inline-block;
}
.item-row .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f8feff;
  min-width: 160px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: -10px;
  top:10px;
}
.item-row .dropdown:hover .dropdown-content {
  display: block;
}
.item-row .dropdown-content.top-dir{
  top: auto;
  bottom: 0;
}
.content-foot{
  padding: 16px 7px 4px 35px;
}
.subs-head{
  padding: 8px 0px 4px 0px;
}
.mrright-5{
  margin-right: 5px;
}
.admin-dd{
  width: 25px;
    text-align: center;
    float: right
}
.admin-dd span{
  cursor: pointer;
}
.admin-dd .dropdown-content{
  min-width: 230px;
  right: 37px;
  top: 17px;
}
.admin-dd .list-group-item{
  text-align: left;
}
.admin-dd li{
  cursor: pointer;
}