.db_sm_widget{
    height: 120px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    text-align: center;
    padding: 15px 10px 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    
}
.db_sm_widget h5 img{
    margin-top: -10px;
}
.db_sm_widget h5 .fa-redo{
    color: #6e6e6e;
    cursor: pointer;
}
.wgt-title{
    color: #5e5e5e;
}
.db_sm_widget.payment_mode_graph,.db_sm_widget.payment_mode_data{
    height: auto;
}
.pmode-data{
    display: flow-root;
    margin-top: 25px;
    margin-bottom: 25px;
}
.pmode-data-name{
    float: left;
    width: 160px;
    text-align: left;
    margin-top: -7px;
}
.pmode-data-bar{
    display: inline-table;
    width: 200px;
    height: 12px;
    background: #ced4da;
    float: left;
}
.pmode-data-bar .inner-data-bar{
    height: 12px;
}
.pmode-data-value{
    float: right;
    margin-top: -7px;
}
.dashboard-setting{
    text-align: right;
}
.dashboard-setting svg{
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.dashboard-setting .dropdown{
    display: block;
}
.dashboard-setting .dropdown .dropdown-menu{
    right: 0;
    left: 100px;
    max-height: 410px;
    overflow: auto;
}
.dashboard-setting .dropdown .dropdown-menu.show{
    display: block;
}
.dropdown-item-title{
    padding: 5px 10px 10px;
    border-bottom: 1px solid #d7d7d7;
    width: 100%;
    color: #9d9999;
}
.sales-sum-sym{
    margin-top: 30px;
    font-size: 40px;
  }
.sales-order-sum-sym{
    margin-top: 30px;
    font-size: 40px;
  }
  
.ssblock.negative{
    color: red;
}
.sales-summary{
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.col-md-1-2 {
    flex: 0 0 4.1666665%;
    max-width: 4.1666665%;
}
.col-md-1-2:not(:last-child) .sales-sum-sym::after {
    content: "-";
}

.col-md-1-2:nth-last-child(2)  .sales-sum-sym::after {
    content: "=";
}
.col-md-1:not(:last-child) .sales-order-sum-sym::after {
    content: "-";
}

.col-md-1:nth-last-child(3)  .sales-order-sum-sym::after {
    content: "=";
}
.sales-summary .db_sm_widget {
    margin-bottom: 0px;
}
@media only screen and (max-width: 480px) {
    .mb-mrbtm-10{
        margin-bottom: 10px;
    }
    .sales-summary{
        padding-bottom: 30px;
    }
    .col-md-1-2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-1-2 .sales-sum-sym,.col-md-1 .sales-order-sum-sym{
        margin: 5px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }
    .col-md-1:not(:last-child) .sales-sum-sym::after {
        content: "Minus(-)";
    }
    .col-md-1:nth-last-child(3) .sales-sum-sym::after {
        content: "Equals(=)";
    }
    .sales-sum-sym{
        font-size: 16px;
        margin: 5px;
    }
    .col-md-1:not(:last-child) .sales-order-sum-sym::after {
        content: "Minus(-)";
    }
    .col-md-1:nth-last-child(3) .sales-order-sum-sym::after {
        content: "Equals(=)";
    }
    .sales-order-sum-sym{
        font-size: 16px;
        margin: 5px;
    }
}