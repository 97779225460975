@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:400,400 italic,400 strong, 500, 500 strong, 600, 700, 700 strong&display=swap');

* {
  box-sizing: border-box;
}
body {
  background: #eee;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
}

body, html, .App, #root {
  width: 100%;
  height: 100%;
  margin-top: 28px;
}

.navbar-light {
  background-color: #ffffff;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: 100px auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 20px 55px 25px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.error{
  color:red;
}
.navbar-expand-lg {
  box-shadow: rgb(0 0 0 / 3%) 4px 4px 4px;
}
.navbar-expand-lg .container .navbar-brand{
  font-size: 30px;
}
@media only screen and (max-width: 480px) {
  .auth-inner {
    width: 95% !important;
  }
  .navbar-expand-lg .container{
    display: revert !important;
  }
 
}